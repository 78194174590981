<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>ItemChecklist</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Check Description"
                    v-model="check.checkDescription"
                    horizontal
                    disabled
                    :invalid-feedback="'Please Enter the Check Description'"
                  />
                  <CInput
                    label="Remarks"
                    v-model="check.remarks"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Remarks'"
                  />
                  <CInput
                    label="Input Value"
                    v-model="check.inputValue"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Input Value'"
                    @blur="$v.job.description.$touch()"
                  />

                  <!-- <div class="form-group row">
						 <label for="example-text-input text-right" class="col-sm-3 col-form-label"> Need to Upload Photo</label>
						 <div class="col-sm-9">
						
							<select  class="form-control"  v-model="check.needtoUpload" :disabled="mode.viewMode" >
										  <option disabled value="">--Select One Option--</option> 
										  <option  value="true">Yes</option>
										  <option  value="false">No</option>
								 </select>
								 </div>
						   </div> -->
                  <!-- <div class="form-group row">
						 <label for="example-text-input text-right" class="col-sm-3 col-form-label"> Need to Give Decimal</label>
						 <div class="col-sm-9">
						
							<select  class="form-control"  v-model="check.inputTypeDecimal" :disabled="mode.viewMode" >
										  <option disabled value="">--Select One Option--</option> 
										  <option  value="1">Yes</option>
										  <option  value="0">No</option>
								 </select>
								 </div>
						   </div>  -->
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" /> Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="saveCheck()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "ItemChecklist",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      checkId: "",
      itemId: "",
      check: {
        itemChecklistId: "0",
        checkDescription: "",
        remarks: "",
        inputValue: "",
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    check: {
      inputValue: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.itemId = this.$route.params.itemId;
      this.itemChecklistId = this.$route.params.itemChecklistId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.itemId = this.$route.params.itemId;
      this.itemChecklistId = this.$route.params.itemChecklistId;
      this.getUser();
    }
  },
  components: { datetime },
  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      window.history.back();
      //this.$router.push({ name: "ItemChecklist",  params: {checkId: this.$route.params.checkId} });
    },

    saveCheck() {
      this.$v.check.$touch();
      if (this.$v.check.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.check));
      } else {
        // fetch(
        //   process.env.VUE_APP_API_HOST +
        //     "/emapp/web/secure/save/submitted/itemChecklist",
        //   {
        //     method: "POST",
        //     headers: {
        //       "Content-type": "application/json",
        //     },
        //     body: JSON.stringify(this.check),
        //   }
        // )

        this.apiPostSecure(
          process.env.VUE_APP_API_HOST +
            "/emapp/web/secure/save/submitted/itemChecklist",
          this.check
        ).then((check) => {
          console.log(JSON.stringify(check));
          window.history.back();
          //this.$router.push({ name: "Checks",  params: {checkId: this.$route.params.checkId} });
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/itemChecklist/" +
          this.itemChecklistId
      )
        .then((response) => response.json())
        .then((data) => (this.check = data));
    },
  },
  mounted() {},
};
</script>
